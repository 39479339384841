import { Injectable, NgZone } from '@angular/core';

import { NavigationService } from './navigation.service';
import { SharedDataService } from './shared-data.service';
import { NotificationService } from './notification.service';
import { Observable } from 'rxjs';
import { LoadingService } from './loader.service';
import { DataService } from './data.service';
import { ApiUrls } from '../constants/apiUrl.constants';

@Injectable()
export class AuthService {

  redirectUrl: string; // URL to redirect to after login
  isChecked = false; // Flag to indicate if a login attempt is in progress
  // tslint:disable-next-line:max-line-length
  constructor(
    private sharedData: SharedDataService, // Service for managing shared data
    private nav: NavigationService, // Service for navigation
    private dataService: DataService, // Service for making API requests
    private notification: NotificationService, // Service for showing notifications
    private ngZone: NgZone, // Angular's NgZone for running code outside Angular's zone
    private loader: LoadingService // Service for handling loading states

  ) {
    // Setting default route to navigate after login
    this.redirectUrl = '/admin/dashboard';
  }
 /**
   * Retrieves the access token from shared data.
   * @returns The access token
   */
  getAccessToken() {
    return this.sharedData.getAttribute('accessToken');
  }
 /**
   * Handles user login by sending user credentials to the server.
   * @param userData User login data
   */
  login(userData: any) {
    this.isChecked = true;
    // Referencing this in this because this refers to the promise itthis. Do this let this = this;
    const data = {
      'value': userData,
      'withCredentials': false
    };
    userData.email = userData.email.toLowerCase()
    console.log(userData)
    this.dataService.post({ url: ApiUrls.Admin_Authentication, data: data, isLoader: true })
      .subscribe((response: any) => {
        const result = response.result;
        if (response.result !== undefined) {

          this.sharedData.setAttribute('userDetails', result);
          this.sharedData.setAttribute('accessToken', result.access_token);
          this.sharedData.setAttribute('refreshToken', result.refresh_token);

          this.getApplicationConstant();
          if (!result?.user_details?.is_email_verified) {
            this.nav.navigateTo(['/auth/email-verification']);
          } else {
            this.nav.navigateTo([this.redirectUrl]);
          }
        } else {
          this.notification.toast(response.error);
        }
        this.isChecked = false;
      },
        () => { this.isChecked = false; }
      );
  }
/**
   * Sends a request to the server to send an email verification code.
   */
  sentEmailVerification() {
    this.dataService.post({ url: ApiUrls.Email_OTP }).subscribe((response: any) => {
      if (response && response.result) {
      } else {
        this.notification.toast(response.error, 'danger');
      }
    });
  }
/**
   * Verifies the email address using the provided passcode.
   * @param passcode The email verification passcode
   */
  emailVerify(passcode: any) {
    const userDetail = this.sharedData.getAttribute('userDetails')?.user_details;
    this.dataService.get({ url: ApiUrls.Email_Verification + '/' + userDetail.email.toLowerCase() + '/' + passcode }).subscribe((response: any) => {
      if (response && response.result) {
        this.nav.navigateTo([this.redirectUrl]);
      } else {
        this.notification.toast(response.error, 'danger');
      }
    });
  }
  /**
   * Retrieves application constants from the server and stores them in shared data.
   */
  getApplicationConstant() {
    this.dataService.get({
      url: ApiUrls.Application_Constant,
      isLoader: false
    }).subscribe((response: any) => {
      if (response && response.result) {
        this.sharedData.setAttribute('applicationConstant', response.result);
      }
    });
  }
/**
   * Logs out the user by clearing shared data and navigating to the login page.
   */
  redirectAfterLogout() {
    this.ngZone.run(() => {
      this.loader.stop();
      console.log('Refresh Token expired');
      this.sharedData.clear();
      this.nav.navigateTo(['/auth/login']);
    });
  }
/**
   * Checks if the user is logged in based on the presence of access token and user details.
   * @returns True if the user is logged in, otherwise false
   */
  isLoggedIn() {
    return Boolean(this.sharedData.getAttribute('accessToken') && this.sharedData.getAttribute('userDetails'));
  }
   /**
   * Retrieves the refresh token from shared data.
   * @returns The refresh token
   */
  getRefreshToken() {
    return this.sharedData.getAttribute('refreshToken');
  }
 /**
   * Refreshes the access token by sending a refresh token to the server.
   * @returns Observable with the response of the refresh token request
   */
  refreshAccessToken(): Observable<any> {
    const data = { refresh_token: this.getRefreshToken() };
    return this.dataService.post({ url: ApiUrls.Admin_Refresh_Token, data, isLoader: false });
  }
/**
   * Logs out the user by sending a logout request to the server and clearing shared data.
   */
  logout() {
    this.dataService.post({ url: ApiUrls.Admin_Logout }).subscribe((result: any) => {
      if (result) {
        this.sharedData.clear();
        this.isChecked = false;
        this.nav.navigateTo(['/auth/login']);
      }
    });
  }
 /**
   * Resets the user's password by sending a password reset request to the server.
   * @param userData The user data including the new password
   */
  resetPassword(userData: any) {
    const data = {
      'value': userData,
      'withCredentials': false
    };

    this.dataService.post({ url: ApiUrls.Admin_Password_Reset, data: data }).subscribe((result: any) => {
      console.log(result);
      this.nav.navigateTo(['/auth/login']);
      this.notification.toast('Check Your Mail ! Click on reset link.');
    },
      () => { }
    );
  }
 /**
   * Sends a request to the server to initiate a password reset process.
   * @param userData The user data including the email address
   */
  forgetPassword(userData: any) {
    // Referencing this in self because this refers to the promise itself. Do this let self = this;
    const self = this,
      data = {
        value: userData,
        noCredentials: true
      };
    data.value.email = data.value.email.toLowerCase()
    console.log(data)
    this.dataService.post({ url: ApiUrls.Admin_Forget_Password, data }).subscribe(
      (response: any) => {
        if (response && response.result) {
          console.log(response);
          self.notification.toast('Please check your mail for reset link!');
          this.sharedData.setAttribute('forgetPasswordEmail', userData.email);
          this.nav.navigateTo(['/auth/reset-password']);
        } else if (response.error) {
          self.notification.toast(response.error, 'danger');
        }
      },
      error => { }
    );
  }
/**
   * Updates the user's password.
   * @param password The new password
   */
  updatePassword(password: any) {
    const data = {
      value: password,
      noCredentials: false
    };
    this.dataService.post({ url: ApiUrls.Admin_Password_Change, data }).subscribe((response: any) => {
      if (response && response.result) {
        this.notification.toast('Password updated successfully!');
        this.nav.navigateTo(['/auth/login']);
        this.sharedData.deleteAttribute('forgetPasswordEmail');
      } else {
        this.notification.toast(response.error, 'danger');
      }
    });
  }
 /**
   * Retrieves the details of the currently logged-in user.
   * @returns The user details if logged in, otherwise an empty object
   */
  getUserDetail() {
    return this.isLoggedIn() ? this.sharedData.getAttribute('userDetails').user_details : {};
  }
  /**
   * Checks if the current user is an admin.
   * @returns True if the user is an admin, otherwise false
   */
  userIsAdmin() {
    return this.isLoggedIn() ? this.sharedData.getAttribute('userDetails').user_details.is_admin : false;
  }
/**
   * Updates the profile of the logged-in user.
   * @param formData The profile data to update
   * @returns Observable with the response of the profile update request
   */
  updateLoginUser(formData: any) {
    return this.dataService.put({
      url: ApiUrls.Profile_Update,
      data: formData,
      loaderName: 'container-loader'
    });
  }
}
