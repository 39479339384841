import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNameByUrl'
})
export class FileNamePipe implements PipeTransform {

  transform(url: string): string|undefined {
    return url.split('/').pop();
  }

}
