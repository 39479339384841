import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(epoche: any): unknown {

    if (!epoche) return '-';
      let d = new Date(epoche * 1000);
      return d.getDate() + " " + ['Jan', 'Feb', 'Mar', 'Apr', 'May',
      'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ][d.getMonth()] + ", " + d.getFullYear();
  }

}
