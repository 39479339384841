import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
    transform(timestamp: number | null): string {
        if (!timestamp) return '';
        const date = new Date(timestamp * 1000);
        const options = { timeZoneName: 'short' };
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return date.toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: timezone,
        }) + ' ' + timezone;
    }
}
