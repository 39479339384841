import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToDaysAbbreviation'
})
export class MinutesToDaysAbbreviationPipe implements PipeTransform {

  transform(minutes: number): string {
    if (minutes < 0) {
      return 'Invalid';
    }

    // Convert minutes to days
    const days = Math.floor(minutes / 1440); // 1440 minutes in a day

    // Check if days are greater than 1000
    if (days >= 1000) {
      // Abbreviate days using K (thousand) and round off to 2 decimal places
      return this.abbreviateDays(days);
    } else {
      return `${days}`;
    }
  }

  private abbreviateDays(days: number): string {
    const k = Math.round(days / 1000 * 100) / 100; // Round off to 2 decimal places
    return `${k}k`;
  }

}
