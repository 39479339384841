import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {


  id: string | null;
  formData: any;
  constructor(
    private notification: NotificationService,
    private nav: NavigationService,
    private authService: AuthService,
    private sharedData: SharedDataService
  ) {
    this.formData = this.authService.getUserDetail();
    this.id = this.formData.id;
  }

  ngOnInit(): void {
  }

  updateProfile(formData: any) {
    this.authService.updateLoginUser(formData).subscribe((response: any) => {
      if (response && response.result) {
        let userDetails = this.sharedData.getAttribute('userDetails');
        userDetails.user_details = { ...userDetails?.user_details, ...formData };
        this.sharedData.setAttribute('userDetails', userDetails);
        this.notification.toast('Profile updated successfully!');
        this.nav.navigateTo([formData?.is_admin ? '/admin/users' : '/admin/dashboard']);
        this.sharedData.emitChange('profile_updated');
      }
    });
  }
}
