import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeText'
})
export class CapitalizeTextPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;
        value = value.toLowerCase()
        if(value=='in-used'){
            return 'In Use'
        }
        // Split the text by dash (-) and capitalize each word
        return value.split('-').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    }
}
