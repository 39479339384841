import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'actionStatus'
})
export class ActionStatusPipe implements PipeTransform {

  transform(status: string): string|undefined {
    return {
      never_used: 'Never Used',
      active: 'Active',
      disabled: 'Disabled'
    }[status];
  }

}
