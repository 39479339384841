<div class="section-header posR {{class[0]}}" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
    <div class="header-right-section {{class[1]}}">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div class="space-right">
                <mat-icon svgIcon="icon-ico-star"></mat-icon>
            </div>
            <h3>
                <ng-content select="#headerLabel"></ng-content>
            </h3>
        </div>
    </div>
    <div class="border-section"></div>
    <!-- <div class="action code">
        <label>Action Code: 2001</label>
    </div> -->
    
</div>