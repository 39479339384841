import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SystemService } from 'src/app/pages/system/system.service';
import { ApiUrls } from '../../constants/apiUrl.constants';
import { AuthService } from '../../services/auth.service';
import { Config, ListService } from '../../services/list.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-lists.component.html',
  styleUrls: ['./campaign-lists.component.scss'],
  providers: [ListService]
})
export class CampaignListComponent implements OnInit {
  systemInfo: any = {};
  logType = '';
  logDetail: any = {};
  @Output() toggle = new EventEmitter();
  userDetails: any = {};
  lists: any;
  scrollDisabled = true;
  systemLogs: any[] = [];
  systemHistoryList: any[] = [];
  id: any;
  camp_id: any;
  historyConfig: Config | undefined;
  isShowLogDetail: boolean = false;
  connectionActivities: any;
  connectivityHeader: string = '';
  isRunningAction=false


  filterKey: any='All Action Tasks';
  show_parent_filter=false
  parent_menu_list: any = ['All Action Tasks','In-Queue Actions','Success Actions','Unsuccessful Actions'];
  showNotRunning=true


  lists_run_report: any;
  run_report_systems: any = [];
  actions_lists:any;


  baseUrl = ApiUrls.Action_Center_List + '?';
  relatedCampaignslists:any
  searchUrl = ApiUrls.Action_Center_List + '/search?';
  campaignsList: any[] = [];
  constructor(
    private systemService: SystemService,
    private authService: AuthService,
    public listService: ListService,
    private dataService: DataService
  ) {
    this.userDetails = this.authService.getUserDetail();
  }

  ngOnInit(): void {

  }


  getCampaignList(campObj: any,type:string) {
 
    const commonListConfig = {
      self: this, scrollContainer: '.sidenav_segmentcount',
      searchUrl: this.searchUrl,

    };
    this.relatedCampaignslists = [
      {
        listName: "campaignsList",
        url: `${this.baseUrl}action_type=${type}&action_code=${campObj.unique_communication_code}&`,
        ...commonListConfig,
        callback: (result: any) => {

        
        },

      },];
    setTimeout(() => {
      this.scrollDisabled = false;
    }, 0);



   
      this.listService.init(this.relatedCampaignslists[0]);

      this.listService.getList(this.relatedCampaignslists[0],true,undefined,'log-information');
  }
  sidebarToggle() {
    this.toggle.emit();
  }

}
