<ng-container>
  <ngx-ui-loader loaderId="log-information"></ngx-ui-loader>
  <mat-toolbar class="dialog-top-bar" fxLayout="row" fxLayoutAlign="space-between center">
    <div></div>
    <div class="title">Related Campaigns</div>
    <div fxLayout="row">
     
    </div>
  </mat-toolbar>
  <div *ngIf="(campaignsList?.length==0) &&(listService.isLoading==false)" style="height: calc(100% - 140px)">
    <div class="selection-section" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

      <div fxLayout="column" fxLayoutAlign="center center" class="width-full">
        <img src="../../../../../assets/images/empty-box.png" style="height: 320px;width: 250px;" alt="no-systems" />
        <p class="custom-time">No Campaigns Found</p>


      </div>

    </div>

  </div>




  <div *ngIf="!(campaignsList?.length==0)" class="sidebar-list-view sidenav_segmentcount"
    style="height: calc(100% - 140px)" [ngClass]="listService.isLoading ? 'blur-effect' : ''" #top infiniteScroll
    [fromRoot]="listService.fromRoot" (scrolled)="listService.getList(relatedCampaignslists[0], false)"
    [infiniteScrollDistance]="listService.scrollDistance" [infiniteScrollThrottle]="listService.scrollThrottle"
    [infiniteScrollContainer]="listService.scrollContainer"
    [infiniteScrollDisabled]="scrollDisabled || relatedCampaignslists[0].scrollDisabled">
    <div class="selection-section" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px"
      style="width: 88%;">
      <div class="width-full profile-padding option-section" *ngFor="let sw of campaignsList; let i = index">

        <div fxLayout="column" fxLayoutAlign="center start" class="width-full">

          <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding">
            <mat-label class="custom-mat-label text-bold">Name: <span class="custom-time cursor-point color-orange-tag" 
              [routerLink]="['/admin/action-center/action-task-result/', sw?.id]">{{ sw?.name |titleCase}}</span></mat-label>
            
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding">
            <mat-label class="custom-mat-label text-bold">{{ sw?.action_code

              }}</mat-label>
            <p class="custom-time  status {{sw.status}}">{{ sw?.status
              |titleCase}}</p>
          </div>



        </div>
      </div>
    </div>

  </div>



  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" class="bottom-fixed action-button-group">
    <button mat-stroked-button color="warn" (click)="sidebarToggle()">
      Cancel
    </button>
    <!-- <button mat-raised-button color="warn" (click)="logType = 'all'; getSystemLogsList(id)">Back</button> -->
  </div>
</ng-container>