import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanLoadModuleGuard } from './core/guard/can-load-module.guard';
import { ModuleAccessGuard } from './core/guard/module-access.guard';
import { AdminLayoutComponent } from './pages/admin-layout/admin-layout.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MyProfileComponent } from './pages/users/my-profile/my-profile.component';

const routes: Routes = [{
  path: 'admin',
  component: AdminLayoutComponent,
  children: [
    {
      path: 'old-dashboard',
      loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      canLoad: [CanLoadModuleGuard]
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./pages/new-dashboard/new-dashboard.module').then(m => m.NewDashboardModule),
      canLoad: [CanLoadModuleGuard]
    },
    {
      path: 'activity-alerts',
      loadChildren: () => import('./pages/activity-alert/activity-alert.module').then(m => m.ActivityAlertModule),
      canLoad: [CanLoadModuleGuard]
    },
    {
      path: 'logs',
      loadChildren: () => import('./pages/logs/logs.module').then(m => m.LogsModule),
      canLoad: [CanLoadModuleGuard]
    },
    {
      path: 'pools',
      loadChildren: () => import('./pages/pools/pools.module').then(m => m.PoolsModule),
      canLoad: [CanLoadModuleGuard]
    },
    {
      path: 'system',
      loadChildren: () => import('./pages/system/system.module').then(m => m.SystemModule),
      canLoad: [CanLoadModuleGuard]
    },
    {
      path: 'new-system',
      loadChildren: () => import('./pages/new-system/new-system.module').then(m => m.NewSystemModule),
      canLoad: [CanLoadModuleGuard]
    },
    {
      path: 'customers',
      loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule),
     // canLoad: [ModuleAccessGuard]
    },
    {
      path: 'users',
      loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'action-type',
      loadChildren: () => import('./pages/action-type/action-type.module').then(m => m.ActionTypeModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'manage-system-segment',
      loadChildren: () => import('./pages/manage-system-segments/manage-system-segments.module').then(m => m.ManageSystemSegmentsModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'manage-system-segment/',
      loadChildren: () => import('./pages/manage-system-segments/manage-system-segments.module').then(m => m.ManageSystemSegmentsModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'action-center',
      loadChildren: () => import('./pages/action-center/action-center.module').then(m => m.ActionCenterModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'action-center/action-task-result',
      loadChildren: () => import('./pages/action-task-result/action-task-result.module').then(m => m.ActionTaskResultModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'action-global-report',
      loadChildren: () => import('./pages/action-global-report/action-global-report.module').then(m => m.ActionGlobalReportModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'settings',
      loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'settings/add-activity',
      loadChildren: () => import('./pages/add-activity/add-activity.module').then(m => m.AddActivityModule),
      canLoad: [ModuleAccessGuard]
    },

    {
      path: 'coming-soon',
      loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'my-profile',
      component: MyProfileComponent
    }

  ]
},
{
  path: 'auth',
  loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
},
{ path: '', redirectTo: 'auth', pathMatch: 'full' },
{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
