import { cloneDeep, forEach } from 'lodash';
import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/constants/apiUrl.constants';
import { DataService } from 'src/app/core/services/data.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { ApplicationConstant } from 'src/app/pages/users/add-user/application-constant';


@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  form: FormGroup;
  applicationConstant: ApplicationConstant | undefined;

  @Input('id') id: string | undefined | null;
  @Input('formData') formData: any | undefined;
  @Output() updateUser = new EventEmitter();

  url: any;
  uploadProgress = -1;
  fileType: any;
  fileName: any;
  validFile = false;
  fileSize: any;
  private subscription: Subscription | undefined;
  profileImgUrl = ''
  phoneNumber: FormGroup;
  constructor(
    private fb: FormBuilder,
    private sharedData: SharedDataService,
    private notification: NotificationService,
    private dataService: DataService,
    private dailogService: DialogService
  ) {
    this.applicationConstant = this.sharedData.getAttribute('applicationConstant');

    this.phoneNumber = this.fb.group({
      type: new FormControl('home', [
        RxwebValidators.required()
      ]),
      code: new FormControl('US', [
        RxwebValidators.required()
      ]),
      number: new FormControl('', [
        RxwebValidators.required(),
        RxwebValidators.digit(),
        RxwebValidators.minLength({ value: 10 }),
        RxwebValidators.maxLength({ value: 12 })
      ]),
      extension: []
    });

    this.form = this.fb.group({
      title: new FormControl('Mr', [
        RxwebValidators.required(),
      ]),
      first_name: new FormControl('', [
        RxwebValidators.required()
      ]),
      middle_name: new FormControl('', [
        // RxwebValidators.required()
      ]),
      last_name: new FormControl('', [
        RxwebValidators.required()
      ]),
      email: new FormControl('', [
        RxwebValidators.required(),
        RxwebValidators.email(),
      ]),
      phone: new FormArray([
        cloneDeep(this.phoneNumber)
      ]),
      is_admin: new FormControl(false)
    });
  }

  get phone(): FormArray {
    return this.form.get('phone') as FormArray;
  }

  addNewPhone() {
    this.phone.push(cloneDeep(this.phoneNumber));
  }

  removePhone(index: number) {
    this.phone.removeAt(index);
  }

  trackByFn(index: number) {
    return index;
  }

  ngOnInit(): void {
    if (this.id) {
      if(this.formData.phone.length > 1) {
        forEach(this.formData.phone, (phoneNumber, index) => {
          index && this.addNewPhone();
        });
      }
      this.form.patchValue(this.formData);
      this.form.controls['email'].disable();
      this.profileImgUrl = this.formData.avatar;
    }
  }

  userRegister(formData: any) {
    this.updateUser.emit({ ...formData, avatar: this.profileImgUrl });
  }

  readUrl(event: any) {
    console.log(event);

    if (event.target.files && event.target.files[0]) {
      this.fileType = event.target.files[0].type.replace('image/', '');
      this.fileName = event.target.files[0].name;
      this.fileSize = event.target.files[0].size;

      // tslint:disable-next-line:max-line-length
      if (['png', 'jpg', 'jpeg'].indexOf(this.fileType) !== -1 && ((this.fileSize / 1048576) <= 2 || ((this.fileSize / 1048576) === 2 && (this.fileSize % 1048576) === 0))) {
        this.validFile = true;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.url = e.target.result;
          this.uploadFile(this.url, this.fileType, this);
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        console.log(this.fileType);
        if (['png', 'jpg', 'jpeg'].indexOf(this.fileType) === -1) {
          this.notification.toast('Supported file types are jpg, png, jpeg');
        } else {
          // if ((this.fileSize / 1024) < 2) {
          //   this.notification.toast('File size is less than 2 kb.');
          // } if ((this.fileSize / 1024 / 1024) > 2) {
          this.notification.toast('File size greater than 2 MB.');
          // } else {

          // }
        }
      }
    }
  }


  uploadFile(file: any, type: any, selfEvent = this) {
    const data = {
      file: file,
      extension: type
    };
    let result: any;
    selfEvent.subscription = selfEvent.dataService.postProgress({ url: ApiUrls.Base64_Upload, data, isLoader: false }).subscribe((resp: any) => {
      console.log(resp);

      if (resp.type === HttpEventType.Response) {
        console.log('Upload complete', resp.body.valueOf());
        this.profileImgUrl = resp.body.valueOf().result.url;
      }
      if (resp.type === HttpEventType.UploadProgress) {
        selfEvent.uploadProgress = Math.round(100 * resp.loaded / resp.total);
        console.log('Progress ' + selfEvent.uploadProgress + '%');
      }
    });
  }

  imagePreview(imgUrl: string) {
    this.dailogService.openImagePreview({ imgUrl: imgUrl, width: '500px' });
  }
}
