import { Injectable } from '@angular/core';
import { ApiUrls } from 'src/app/core/constants/apiUrl.constants';
import { DataService } from 'src/app/core/services/data.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(
    private dataService: DataService,
    private notification: NotificationService,
    private nav: NavigationService
  ) { }

  getSystemList(filters = '') {
    return this.dataService.get({ url: ApiUrls.System_List + "?skip=0&limit=15&" + filters, loaderName: 'container-loader' });
  }

  getSystemInfo(id: string, loaderName = 'container-loader') {
    return this.dataService.get({ url: ApiUrls.System + '/' + id, loaderName: loaderName });
  }

  updateSystem(formData: any, id = '') {
    console.log(id)
    if (!id) {
      delete formData.system_serial_number;
    }

    this.dataService[id ? 'put' : 'post']({
      url: ApiUrls[id ? 'System' : 'System_Create'] + (id ? ('/' + id) : ''),
      data: formData,
    }).subscribe((response: any) => {
      if (response && response.result) {

        this.notification.toast('System ' + (id ? 'Updated' : 'Created') + ' Successfully.');
        // this.nav.navigateTo(['/admin/system']);
      }
    });
  }

  getSystemReport(id: string) {
    return this.dataService.get({
      url: ApiUrls.System_Report + '/' + id,
      isLoader: false
    });
  }

  getSystemLogs(id: string, filterString = '') {
    return this.dataService.get({
      url: ApiUrls.System_Logs + '?system=' + id + '&?skip=0&limit=15&' + filterString,
      isLoader: false
    });
  }

  updateSystemNotes(formData: any, id: string, loaderName?: any) {
    this.dataService.post({
      url: ApiUrls.System_Notes + '/' + id,
      data: formData,
      loaderName: loaderName
    }).subscribe((response: any) => {
      if (response && response.result) {
        console.log(response.result);
        this.notification.toast('System Notes Updated Successfully.');
        // this.nav.navigateTo(['/admin/system']);
      }
    });
  }

  getSystemNotes(id: string) {
    return this.dataService.get({
      url: ApiUrls.System_Notes + '/' + id,
      loaderName: 'occupent-info-loader'
    });
  }

  getSystemLogsList(id: String, log_key = '') {
    return this.dataService.get({
      url: ApiUrls.Logs + '?skip=0&limit=15&system=' + id + '&' + (log_key != '' ? 'log_key=' + log_key : ''),
      loaderName: 'log-information'
    });
  }

  searchSystem(value: string, searchFields = ['system_serial_number', 'status']) {
    const data = {
      query: {
        // tslint:disable-next-line:max-line-length
        keyword: value.replace(/[`~!#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, ''), fields: searchFields
      }
    };

    return this.dataService.post({
      url: ApiUrls.System_List_Search,
      data,
      isLoader: false
    });
  }

  exportLogs(data: any) {
    return this.dataService.post({ url: ApiUrls.Log_Export, data, loaderName: 'export-log' });
  }

}
