<div class="section-header posR {{ class[0] }}" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
  <div class="header-right-section {{ class[1] }}">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="space-right">
        <mat-icon svgIcon="icon-ico-star"></mat-icon>
      </div>
      <h3>
        <ng-content select="#headerLabel"></ng-content>
      </h3>
    </div>
  </div>
  <div class="border-section"></div>
  <div class="button-section" *ngIf="isButtonIconShow">
    <button mat-icon-button flex fxLayoutAlign="space-between center" *ngIf="isButtonIcon1Show" (click)="clickHandler1()">
      <ng-content select="#buttonLabel2"></ng-content>
    </button>
    <button mat-icon-button flex fxLayoutAlign="space-between center" *ngIf="isButtonIcon2Show" (click)="clickHandler2()">
      <ng-content select="#buttonLabel3"></ng-content>
    </button>
    <button mat-icon-button flex fxLayoutAlign="space-between center" *ngIf="isButtonIcon3Show" (click)="clickHandler3()">
      <ng-content select="#buttonLabel4"></ng-content>
    </button>
    <button mat-icon-button flex fxLayoutAlign="space-between center" *ngIf="isButtonIcon4Show" (click)="clickHandler4()">
      <ng-content select="#buttonLabel5"></ng-content>
    </button>
    <button class="heading-button" *ngIf="isButtonShow" mat-raised-button color="warn" (click)="goTo()" [disabled]="isdisabled">
      <ng-content select="#buttonLabel"></ng-content>
    </button>
  </div>
</div>