  import { Pipe, PipeTransform } from '@angular/core';

  @Pipe({
    name: 'titleCase'
  })
  export class TitleCasePipe implements PipeTransform {

    transform(title: string): string|undefined {
      if(typeof title !== 'string') {
        return title;
      }
 
      // Define the phrase mapping directly in the method
    const phraseMap: { [key: string]: string } = {
      'recently_connected': 'rece. connected',
      'recently_disconnected': 'rece. disconnected',
      'connected': 'connected',
      'disconnected': 'disconnected',
      'offline': 'offline',
      'never_connected': 'ne. connected'
    };

    // Replace specific phrases using the mapping
    for (const [key, value] of Object.entries(phraseMap)) {
      const regex = new RegExp(key.replace(/-/g, '\\-'), 'g'); // Create a regex for the key
      title = title.replace(regex, value);
    }
      title=title.toLowerCase()
      return title.split(/\s*\_\s*/g).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

  }
