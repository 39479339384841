
export enum ApiUrls {
  Admin_Authentication = 'v1/business/user/auth/login',
  Admin_Refresh_Token = 'v1/business/user/auth/refresh',
  Admin_Forget_Password = 'v1/business/user/auth/password/email',
  Admin_Password_Reset = 'v1/business/user/auth/password/reset',
  Admin_Logout = 'v1/business/user/auth/logout',
  Admin_Password_Change = 'v1/portal/user/password/change',
  Admin_Settings = 'v2/portal/user-settings',

  Email_Verification = 'v1/portal/user/email/verify',
  Email_OTP = "portal/user/email/resend",
  User = 'v1/portal/user',
  System_detail = 'v2/portal/project/get-system-detail',
  User_Update = 'v1/portal/user-update',
  User_List = 'v2/portal/users/list',
  User_List_Search = 'v2/portal/users/list/search',

  Country_Code = 'v1/portal/country-code',
  Application_Constant = 'v1/portal/application-constant',
  User_Register = 'v1/portal/users/register',
  Dashbord_System_Summary = 'v1/portal/dashboard/systems',
  Dashboard_Water_Summary = 'v1/portal/dashboard/water-saving',
  Dashboard_Report_Base = 'v2/portal/dashboard/',
  System_Report_Base = 'v2/portal/system-report/',


  Customer = 'v2/portal/customer',
  CustomerPut = 'v2/portal/user/update',
  Customer_List = 'v2/portal/customers/list',
  Customer_List_Search = 'v2/portal/customers/list/search',
  Customer_List_Create = 'v2/portal/customers/list',
  Customer_activities = 'v2/portal/user-activity',
  customerAssignmentHistory = 'v2/portal/system-assignment-history',


  System_List = 'v2/portal/systems/list',
  Segmented_Systems = 'v2/portal/segmented-systems',
  Action_Systems = 'v2/portal/action',
  SegmentLogsExportHistory = 'v2/portal/segment-logs-export-history',
  Segment_report = 'v2/portal/segment-reports',
  Parent_Menu_list = 'v2/portal/parent-menu-list',
  Child_Menu_list = 'v2/portal/child-menu-list',
  System_List_Search = 'v2/portal/systems/list/search',
  System = 'v2/portal/system',
  System_Create = 'v2/portal/system/create',
  System_Report = 'v2/portal/system/report',
  System_Logs = 'v2/portal/logs',
  System_Logs_Search = 'v2/portal/logs/search',
  System_Notes = 'v2/portal/system/notes',
  System_Mac_Swap='v2/portal/system-swap/mac-address',
  System_Serial_Swap='v2/portal/system-swap/serial-number',


  Project_Create = 'v2/portal/project',
  Alarm_Create = 'v2/portal/alarm',

  Pools = 'v2/portal/pool',
  Pools_List_Search='v2/portal/pool/list/search',
  Pool_Create = 'v2/portal/pool/create',
  Pool_Update = 'v2/portal/pool/',
  Pools_Delete = 'v2/portal/pool/multi/delete',

  Profile_Update = 'v1/portal/user-update',

  Assign_History = 'v1/portal/assignment-history',

  Logs = 'v2/portal/logs',
  OTA = 'v2/portal/action',
  Logs_Search = 'v2/portal/logs/search',
  Log_Export = 'v2/portal/logs/export',
  Log_Export_History = 'v2/portal/logs/export/history',
  Base64_Upload = 'v2/portal/upload/base64',
  File_Upload = 'v2/portal/upload',
  Pools_Import = 'v2/portal/pool/bulk/process',

  Activity_Alert = 'v2/portal/system-active-alerts',
  Clear_Alerts = 'v2/portal/system-active-alerts/clear',


  // action type url
  Action_type = 'v2/portal/software',
  Action_delete = 'v2/portal/software',
  Action_fileUpload = 'v2/portal/software',
  Action_type_list = 'v2/portal/software',
  Software_Updates = 'v2/portal/software?action_type=software_update&',
  Config_Updates = 'v2/portal/config?action_type=config_update&',
  Remote_Commands = 'v2/portal/remote-command?action_type=remote_command&',
  System_Info_List = 'v2/portal/system-info?action_type=system_info&',
  Config_Update = 'v2/portal/config',
  System_Info = 'v2/portal/system-info',
  Remote_Command = 'v2/portal/remote-command',
  Action_Center_List = 'v2/portal/action-list',
  Action_Center = 'v2/portal/action',
  Action_Center_Add = 'v2/portal/action-add',
  Action_Center_Update = 'v2/portal/action-update',
  Roll_Out_Action = 'v2/portal/action-roll-out',
  Roll_Out_Action_Stage_2 = 'v2/portal/action-roll-out-stage-two',
  Roll_Back_Action = 'v2/portal/action-roll-back',
  Review_For_Desable = 'v2/portal/review-system-before-disabling',
  Desable_System = 'v2/portal/disable-system',
  Actions_Delete = 'v2/portal/action-multi-delete',
  Action_Delete = 'v2/portal/action',
  Action_details = 'v2/portal/action-details',
  Action_type_Delete = 'v2/portal',

  //  segemnt api 
  System_Segments = "v2/portal/segment",
  System_Associated_Segments = "v2/portal/system-associated-segments",
  Reports_Of_Segment = 'v2/portal/reports-of-segment/',

  Roll_Out_Settings = 'v2/portal/settings-rollout-setup',
  Get_Roll_out_Settings = 'v2/portal/settings',

  Timezone_List = "v2/portal/timezone?limit=unlimited&order_by=region,asc",

  //remove systems
  Remove_System = 'v2/portal/remove-system/',


  //tank_levels_api
  Tank_BaseURL = 'v2/portal/system-tank-level-report/',
  User_Report='v2/portal/user-report/'

}
