import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SystemService } from 'src/app/pages/system/system.service';
import { ApiUrls } from '../../constants/apiUrl.constants';
import { AuthService } from '../../services/auth.service';
import { Config, ListService } from '../../services/list.service';
import { DataService } from '../../services/data.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-system-details',
  templateUrl: './system-details.component.html',
  styleUrls: ['./system-details.component.scss'],
  providers: [ListService]
})
export class SystemDetailsComponent implements OnInit {
  systemInfo: any = {};
  logType = '';
  logDetail: any = {};
  @Output() toggle = new EventEmitter();
  userDetails: any = {};
  lists: any;
  scrollDisabled = true;
  systemLogs: any[] = [];
  systemHistoryList: any[] = [];
  id: any;
  camp_id: any;
  historyConfig: Config | undefined;
  isShowLogDetail: boolean = false;
  connectionActivities: any;
  connectivityHeader: string = '';
  isRunningAction=false


  filterKey: any='All Action Tasks';
  show_parent_filter=false
  parent_menu_list: any = ['All Action Tasks','In-Queue Actions','Success Actions','Unsuccessful Actions'];
  showNotRunning=true


  lists_run_report: any;
  run_report_systems: any = [];
  baseUrl = ApiUrls.Action_Center + '?';
  searchUrl = ApiUrls.Action_Center + '/search?';
  actions_lists:any;
  campObj:any;
  segment_id:any;
  list_actions=false
  i_index=0;

  searchText = '';
  isSearchSystem = false;
  showSearch=false
  constructor(
    private systemService: SystemService,
    private authService: AuthService,
    public listService: ListService,
    
    private notification: NotificationService,
    private dataService: DataService
  ) {
    this.userDetails = this.authService.getUserDetail();
  }

  ngOnInit(): void {

  }

  getSystemLogsList(id: string, log_key = '') {
    setTimeout(() => {
      this.scrollDisabled = false;
    }, 0);

    this.id = id;
    this.lists = [{
      self: this,
      listName: "systemLogs",
      url: ApiUrls.Logs + '?system=' + id + '&',
      scrollContainer: '.sidebar-list-view'
    }];

    this.systemService.getSystemLogsList(id, log_key).subscribe((response: any) => {
      if (response && response.result) {
        this.listService.init(this.lists[0], response.result.records);
      }
    });
  }

  getLogInformation(id: string, log_key = '', isShowLogDetail = true) {
    this.logType = 'all';
    this.isShowLogDetail = isShowLogDetail;
    this.systemService.getSystemInfo(id, 'log-information')
      .subscribe((response: any) => {
        if (response && response.result) {
          this.systemInfo = response.result;
          this.getSystemLogsList(id, log_key);
        }
      });
  }

  getPickSystem() {
    this.logType = 'picksystem';
  }

  getPickProject() {
    this.logType = 'pickproject';
  }

  systemList(element: any, id: string,fromFailed?:any) {
    this.showSearch=false;
    this.segment_id=id
    this.list_actions=false;
    this.isSearchSystem=false;

    this.filterKey='All Action Tasks';
    this.campObj=element
    this.show_parent_filter=false
    if(element?.system && element?.status=='not-running'){
      this.isRunningAction=false;
            this.run_report_systems=[]
      this.run_report_systems[0]=element.systems
      return
    }

    setTimeout(() => {
      this.scrollDisabled = false;
    }, 0);
    if(element?.created_by_user){
      this.showNotRunning=false;
    }else{
      this.showNotRunning=true
    }
    if(element=='sd'){
      this.showNotRunning=false
    }
    if(element?.status=='running' || element?.status=='completed' || element?.status=='scheduled'){
    this.show_parent_filter=true

      this.isRunningAction=true
    this.camp_id=element.id
    if(fromFailed==true){
    
   return  this.getActions('Unsuccessful Actions')
     
    }
   
    if(element?.system){
      this.lists_run_report = [
        {
          self: this,
          listName: "run_report_systems",
          url: ApiUrls.Action_Systems+'?action_queue_id='+element.id+"&",
          scrollContainer: '.sidenav_segmentcount',
          callback: (result: any) => {
          }
        }
      ]
    }else{
      this.isRunningAction=false
      this.segment_id=id
      this.lists_run_report = [
        {
          self: this,
          listName: "run_report_systems",
          url: ApiUrls.Segmented_Systems + '/' + id + '?',
          scrollContainer: '.sidenav_segmentcount',
          callback: (result: any) => {
          }
        }
      ]
    }
    }
    
    else{
      this.isRunningAction=false
      this.lists_run_report = [
        {
          self: this,
          listName: "run_report_systems",
          url: ApiUrls.Segmented_Systems + '/' + id + '?',
          scrollContainer: '.sidenav_segmentcount',
          callback: (result: any) => {
          }
        }
      ]
    }

    
    this.listService.init(this.lists_run_report[0]);
    this.listService.getList(this.lists_run_report[0],true,undefined,'log-information');
  }

  getSameLogHistory(id: string, log_key: any) {
    this.historyConfig = {
      self: this,
      listName: "systemHistoryList",
      url: ApiUrls.Logs + '?system=' + id + '&log_key=' + log_key + '&',
      skip: 0,
      limit: 15,
      scrollContainer: '.sidebar-list-view'
    };

    this.systemService.getSystemLogsList(id, log_key)
      .subscribe((response: any) => {
        if (response && response.result) {
          console.log(response.result);

          this.listService.init(this.historyConfig!, response.result.records);
        }
      });
  }

  sidebarToggle() {
    this.toggle.emit();
  }
  getActions(e:any){
    this.isSearchSystem=false;

    this.list_actions=true

    this.filterKey=e;
    this.isRunningAction=true
    this.run_report_systems=[];
    const commonListConfig = {
      self: this,
      searchUrl: this.searchUrl,
      callback: (result: any) => {
        if (result.stats) {
         // this.stats[this.lists[this.tabIndex].listName] = result.stats;
        }
      }
    };
// Lists configuration
this.actions_lists = [ {
  listName: "run_report_systems",
  scrollContainer: '.sidenav_segmentcount',
  url: this.baseUrl + `action_queue_id=${this.camp_id}&status[]=IN-PROGRESS&status[]=WAITING&status[]=SCHEDULED&`,
  ...commonListConfig
}, {
  listName: "run_report_systems", scrollContainer: '.sidenav_segmentcount',
  url: this.baseUrl + `action_queue_id=${this.camp_id}&status[]=COMPLETED&`,
  ...commonListConfig
}, {
  listName: "run_report_systems", scrollContainer: '.sidenav_segmentcount',
  url: this.baseUrl + `action_queue_id=${this.camp_id}&status[]=FAILED&status[]=REMOVED&status[]=EXPIRED&`,
  ...commonListConfig
}];
setTimeout(() => {
  this.scrollDisabled = false;
}, 0);
if (e === 'In-Queue Actions') {
  // Logic for In-Queue Actions
  this.i_index=0

  this.listService.init(this.actions_lists[0]);
  this.listService.getList(this.actions_lists[0],true,undefined,'log-information');
  console.log("Handling In-Queue Actions");
} else if (e === 'Success Actions') {
  // Logic for Success Actions
  this.i_index=1

  this.listService.init(this.actions_lists[1]);
  this.listService.getList(this.actions_lists[1],true,undefined,'log-information');
  console.log("Handling Success Actions");
} else if (e === 'Unsuccessful Actions') {
  // Logic for Unsuccessful Actions
  this.i_index=2
  this.listService.init(this.actions_lists[2]);
  this.listService.getList(this.actions_lists[2],true,undefined,'log-information');
  console.log("Handling Unsuccessful Actions");
} else {
  // this.lists_run_report = [
  //   {
  //     self: this,
  //     listName: "run_report_systems",
  //     url: ApiUrls.Action_Systems+'?action_queue_id='+this.camp_id+"&",
  //     scrollContainer: '.sidenav_segmentcount',
  //     callback: (result: any) => {
  //     }
  //   }
  // ]
  this.list_actions=false
  
  if(this.campObj?.system){
    this.lists_run_report = [
      {
        self: this,
        listName: "run_report_systems",
        url: ApiUrls.Action_Systems+'?action_queue_id='+this.campObj.id+"&",
        scrollContainer: '.sidenav_segmentcount',
        callback: (result: any) => {
        }
      }
    ]
  }else{
    this.isRunningAction=false
    this.lists_run_report = [
      {
        self: this,
        listName: "run_report_systems",
        url: ApiUrls.Segmented_Systems +"/"+ this.segment_id + '?',
        scrollContainer: '.sidenav_segmentcount',
        callback: (result: any) => {
        }
      }
    ]
  }
  
  this.listService.init(this.lists_run_report[0]);
  this.listService.getList(this.lists_run_report[0],true,undefined,'log-information');
}

  }

  searchSystems(q:any){
    if(this.searchText.trim()==''){
      this.notification.toast('Please add search text')
      return
    }
    this.isSearchSystem=true
    setTimeout(() => {
      this.scrollDisabled = false;
    }, 0);

    this.lists_run_report = [
      {
        self: this,
        listName: "run_report_systems",
        url: ApiUrls.Segmented_Systems + '/' + this.segment_id + '?'+'mac_address=\\*'+`${q}` +'\\*'+'&',
        scrollContainer: '.sidenav_segmentcount',
        callback: (result: any) => {
        }
      }
    ]
    
    this.listService.init(this.lists_run_report[0]);
    this.listService.getList(this.lists_run_report[0],true,undefined,'log-information');
  }
}
