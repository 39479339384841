import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'greyter-new-action-type-header',
  templateUrl: './new-action-type-header.component.html',
  styleUrls: ['./new-action-type-header.component.scss']
})


export class NewActionTypeHeaderComponent {

  @Input() isButtonShow: Boolean = true;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  @Input() routeLink: string[] = [];
  @Input() class: string[] = [];

  constructor(
    private nav: NavigationService
  ) {
    
  }

  goTo() {
    if (this.routeLink.length) {
      this.nav.navigateTo(this.routeLink);
    } else {
      this.buttonClick.emit();
    }
  }
}
