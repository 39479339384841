import { Pipe, PipeTransform } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';

@Pipe({
  name: 'projectType'
})
export class ProjectTypePipe implements PipeTransform {

  projectType: any[] = [];
  constructor(
    private sharedData: SharedDataService
  ) {
    this.projectType = this.sharedData.getAttribute('applicationConstant')?.project_type;
  }
  transform(projectType: string): unknown {
    return this.projectType.filter((item) => { return item.value == projectType })[0].display_value;
  }

}
