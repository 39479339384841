import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'greyter-multi-button-header',
  templateUrl: './multi-button-header.component.html',
  styleUrls: ['./multi-button-header.component.scss']
})
export class MultiButtonHeaderComponent {

  @Input() isButtonIconShow: Boolean = true;
  @Input() isButtonIcon1Show: Boolean = true;
  @Input() isButtonIcon2Show: Boolean = true;
  @Input() isButtonIcon3Show: Boolean = true;
  @Input() isButtonIcon4Show: Boolean = true;
  @Input() isButtonShow: Boolean = true;
  @Input() isdisabled: Boolean = false;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  @Output() customClick1: EventEmitter<any> = new EventEmitter();
  @Output() customClick2: EventEmitter<any> = new EventEmitter();
  @Output() customClick3: EventEmitter<any> = new EventEmitter();
  @Output() customClick4: EventEmitter<any> = new EventEmitter();
  @Input() routeLink: string[] = [];
  @Input() class: string[] = [];

  constructor(
    private nav: NavigationService
  ) {
    console.log('isdisabled', this.isdisabled);
  }

  clickHandler1() {
    this.customClick1.emit();
  }
  clickHandler2() {
    this.customClick2.emit();
  }
  clickHandler3() {
    this.customClick3.emit();
  }
  clickHandler4() {
    this.customClick4.emit();
  }

  goTo() {
    if (this.routeLink.length) {
      this.nav.navigateTo(this.routeLink);
    } else {
      this.buttonClick.emit();
    }
  }
}
