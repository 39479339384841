import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(data: any, ...args: unknown[]): unknown {

    if (!data?.first_name) {
      return '-';
    }

    const fullName = `${data.first_name ?? ''} ${data.last_name ?? ''}`.trim();
    return fullName;
  }
  capitalizeFirstLetter(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

}
