import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCase2'
})
export class TitleCasePipe2 implements PipeTransform {

  transform(title: string): string|undefined {
    if(typeof title !== 'string') {
      return title;
    }

 
    title=title.toLowerCase()
    return title.split(/\s*\_\s*/g).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

}
